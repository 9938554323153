<template>
  <ValidationObserver
    ref="validationObserver"
    @input="handleFormChangeLive"
  >
    <div v-if="isEdit">
      <Loader :isVisible="isLoading" />
    </div>
    <div class="media-information-wrapper">
      <div class="media-information">
        <div class="header">
          <div style="display:flex">
            <div
              v-if="!isEdit"
              class="system"
            >
              Media
            </div>
            <div :class="['main-title', isEdit && 'title editor']">
              {{ !isEdit ? ' / Add Live Stream' : 'Live Stream Editor' }}
            </div>
          </div>
          <div style="display:flex;">
            <Button
              v-if="!isEdit"
              :title="'Cancel'"
              :classType="'warning'"
              :eventFunction="backTo"
            />
            <div class="apply-btn">
              <Button
                :title="!isEdit ? 'Add' : 'Apply'"
                :classType="'primary'"
                :eventFunction="addLiveStream"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!isEdit"
          class="title"
        >
          Add Live Stream
        </div>
        <Tabs :onSelectTab="handleSelectTab">
          <Tab
            id="general"
            name="General"
            :selected="true"
            class="wrapper-general"
            :className="[tabErrors.general ? 'error-tab' : 'tab']"
          >
            <div class="general-form">
              <MaterialInput
                v-model="formData.mediaTitle"
                v-bind="formFields.mediaTitle"
                :requiredFromBack="true"
              />
              <MaterialTextArea
                v-model="formData.description"
                v-bind="formFields.description"
              />
              <div class="categories-wrap">
                <CategoriesManagement
                  label="Select Categories"
                  :selectedValue="formData.categories"
                  :onChange="handleCategoryChange"
                  :commonError="categoriesError"
                  :required="false"
                />
              </div>
              <div>
                <div class="title poster-image-title">
                  Poster Image
                </div>
                <PosterImage
                  ref="posterImage"
                  :poster="isEdit ? getPosterUrl(mediaItem) : ''"
                  :onPosterChange="handlePosterChange"
                />
              </div>
            </div>
          </Tab>
          <Tab
            id="source"
            class="live-types-tab"
            name="Stream Source"
            :selected="false"
            :className="[tabErrors.source
              ? 'error-tab'
              : 'tab']"
          >
            <div>
              <div class="live-transcode">
                <div class="title">
                  Stream Source
                </div>
                <div class="radios-group">
                  <div
                    v-for="item in liveTypes"
                    id="group1"
                    :key="item.code"
                    class="radio-type"
                  >
                    <MaterialRadio
                      :label="item.name"
                      :value="item.code"
                      :formData="formData"
                      :name="'group1'"
                      :className="'radio-input'"
                      :groupClass="'radio-itunes'"
                      :isLiveRadio="true"
                      :onChange="onLiveTypeChange"
                      :disabled="isEdit && item.code !== formData.mode"
                    />
                  </div>
                </div>
                <div>
                  <div
                    v-if="formData.mode === 'transcode'"
                    class="transcode-tab"
                  >
                    <div class="flex">
                      <div class="event-name">
                        <MaterialInput
                          v-model="formData.deliveryEventName"
                          v-bind="formFields.deliveryEventName"
                          :requiredFromBack="true"
                          :disabled="isEdit"
                        />
                      </div>
                      <div>
                        <div class="title">
                          Recording Options
                        </div>
                        <div class="wrapper-toggles">
                          <div class="toggle-wrapper">
                            <ToggleButton
                              v-model="formData.isToggleRecord"
                              color="#2591CF"
                              :sync="true"
                              :labels="false"
                              :disabled="isRecorderDisabled(mediaItem)"
                              @change="handleRecorderToggle()"
                            />
                            <div class="toggle-title">
                              Recording
                            </div>
                          </div>
                          <div
                            v-if="formData.isToggleRecord"
                            class="toggle-wrapper"
                          >
                            <ToggleButton
                              v-model="formData.isToggleApprove"
                              color="#2591CF"
                              :sync="true"
                              :labels="false"
                              :disabled="isRecorderDisabled(mediaItem)"
                            />
                            <div class="toggle-title">
                              Auto-Approve
                            </div>
                          </div>
                        </div>
                        <div v-if="formData.isToggleRecord">
                          <div class="wrapper-dates">
                            <div class="date-time-input-wrapper">
                              <MaterialCalendar
                                v-model="formData.startDate"
                                v-bind="formFields.startDate"
                                :label="'Start Date'"
                                :disabled="isRecorderDisabled(mediaItem)"
                              />
                            </div>
                            <div class="date-time-input-wrapper">
                              <MaterialCalendar
                                v-model="formData.endDate"
                                v-bind="formFields.endDate"
                                :label="'End Date'"
                                :disabled="isRecorderDisabled(mediaItem)"
                              />
                            </div>
                          </div>
                          <div class="wrapper-dates">
                            <div
                              style="margin-right: 20px"
                              class="time-block"
                            >
                              <MaterialTime
                                v-model="formData.startTime"
                                v-bind="formFields.startTime"
                                :label="'Start Time'"
                                :required="true"
                                :disabled="isRecorderDisabled(mediaItem)"
                              />
                            </div>
                            <div class="time-block">
                              <MaterialTime
                                v-model="formData.endTime"
                                v-bind="formFields.endTime"
                                :label="'End Time'"
                                :required="true"
                                :disabled="isRecorderDisabled(mediaItem)"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="formData.isToggleRecord"
                          class="wrapper-days-recurring"
                        >
                          <div class="wrapper-days">
                            <div>
                              <div
                                v-if="formData.isToggleRecord"
                                class="toggle-wrapper"
                              >
                                <ToggleButton
                                  v-model="formData.isToggleRecurring"
                                  color="#2591CF"
                                  :sync="true"
                                  :labels="false"
                                  :disabled="isRecorderDisabled(mediaItem)"
                                />
                                <div class="toggle-title">
                                  Enable Recurring
                                </div>
                              </div>
                              <div
                                v-if="formData.isToggleRecurring"
                                class="record"
                              >
                                <div>
                                  Record Every:
                                </div>
                                <div class="wrapper-week">
                                  <MaterialDayCheckbox
                                    :week="formData.week"
                                    :error="recurringErrors"
                                    :disabled="!formData.isToggleRecurring
                                      || isRecorderDisabled(mediaItem)
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="wrapper-folders">
                            <FoldersManagement
                              :selectedValue="formData.folderId"
                              v-bind="formFields.folderId"
                              :label="'Target Media Folder'"
                              :required="true"
                              :commonError="foldError"
                              :onChange="onFoldersChange"
                              :disabled="isRecorderDisabled(mediaItem)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="formData.mode === 'payback'"
                    class="live-transmux"
                  >
                    <div class="hls-payback">
                      <MaterialInput
                        v-model="formData.hlsPaybackUrl"
                        v-bind="formFields.hlsPaybackUrl"
                        :requiredFromBack="true"
                      />
                      <label class="live-preview">Live Preview</label>
                      <div class="live-preview">
                        <div
                          v-if="!formData.hlsPaybackUrl"
                          class="empty-player"
                        >
                        </div>
                        <div v-else>
                          <BitmovinPlayer
                            :key="formData.hlsPaybackUrl"
                            :isPayback="false"
                            :mediaItem="{
                              playerSource: {
                                hls: formData.hlsPaybackUrl
                              }
                            }"
                            :isControls="false"
                            :isAddLive="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="dash-payback">
                      <MaterialInput
                        v-model="formData.dashPaybackUrl"
                        v-bind="formFields.dashPaybackUrl"
                        :requiredFromBack="true"
                      />
                      <label>Live Preview</label>
                      <div class="live-preview">
                        <div
                          v-if="!formData.dashPaybackUrl"
                          class="empty-player"
                        >
                        </div>
                        <div v-else>
                          <BitmovinPlayer
                            :key="formData.dashPaybackUrl"
                            :isPayback="false"
                            :mediaItem="{
                              playerSource: {
                                dash: formData.dashPaybackUrl
                              }
                            }"
                            :isControls="false"
                            :isAddLive="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="formData.mode === 'transcode'"
              class="column external-delivery"
            >
              <ExternalDeliveryTargets
                :deliveryTargets="formData.deliveryTargets"
                :errors="errors"
                :isLive="true"
              />
            </div>
          </Tab>
          <Tab
            v-if="!isEdit"
            id="availability"
            name="Availability"
            :selected="false"
            :className="[tabErrors.availability
              ? 'error-tab'
              : 'tab']"
          >
            <Availability
              :availabilityExpiration="availabilityExpiration"
              :isAddLive="true"
            />
          </Tab>
        </Tabs>
      </div>
      <div v-if="live.type === 'livestream' && live.credentials && isOpen">
        <WowzaCredentials
          :credentials="live.credentials"
          :isOpen="isOpen"
          :handleClose="handleClose"
        />
      </div>
      <NotificationModal
        v-if="isRecorderToggleOpen"
        :modal="true"
        :bodyMessage="getRecorderToggleConfirmationMessage()"
        :onDecline="handleRecorderToggleDecline"
        :onClose="handleRecorderToggleClose"
      />
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import 'moment-timezone';
import {
  ADD_LIVE_STREAM_REQUEST,
  CHANGE_DELIVERY_TARGET_REQUEST,
  EDIT_LIVE_STREAM_REQUEST,
  GET_FOLDERS_LIST_REQUEST,
} from '../../store/actions/liveEvent/liveEvent';
import posterSelector from '../../utils/poster/selector';
import CONFIG from '../../constants/config';

import Button from '../common/Button.vue';
import Tabs from '../common/Tabs/Tabs.vue';
import Tab from '../common/Tabs/Tab.vue';
import BitmovinPlayer from '../common/BitmovinPlayer.vue';
import WowzaCredentials from '../WowzaCredentials.vue';
import ExternalDeliveryTargets from '../liveStream/deliveryTargets/ExternalDeliveryTargets.vue';
import PosterImage from '../common/PosterImage/DragAndDropFileUploader.vue';
import MaterialCalendar from '../inputs/MaterialCalendar.vue';
import MaterialTime from '../inputs/MaterialTime.vue';
import MaterialDayCheckbox from '../inputs/MaterialDayCheckbox.vue';
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import NotificationModal from '../NotificationModal.vue';
import Availability from '../media/inspector/Availability.vue';
import Loader from '../common/Loader.vue';
import MaterialRadio from '../inputs/MaterialRadio.vue';
import FoldersManagement from '../FoldersManagement.vue';
import CategoriesManagement from '../inputs/CategoriesManagement.vue';

export default {
  name: 'AddLiveStreamForm',
  components: {
    FoldersManagement,
    Loader,
    Availability,
    MaterialDayCheckbox,
    MaterialTime,
    MaterialCalendar,
    PosterImage,
    BitmovinPlayer,
    MaterialInput,
    MaterialTextArea,
    NotificationModal,
    WowzaCredentials,
    ExternalDeliveryTargets,
    Button,
    Tabs,
    Tab,
    MaterialRadio,
    CategoriesManagement,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'addStream',
    },
    handleFormChange: {
      type: Function,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    mediaItem: {
      type: Object,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      foldError: '',
      categoriesError: '',
      errorMessage: CONFIG.errorMessages.commonServerError,
      liveTranscodeError: null,
      availErr: null,
      posterSelector,
      startDateEdit: '',
      errors: [],
      recurringErrors: '',
      isOpen: false,
      isRecorderToggleOpen: false,
      recorderToggleConfirmationMessage: CONFIG.modals.recorderToggleConfirmationMessage,
      selectedTags: [],
      isPosterChanged: false,
      startDateChanged: false,
      successMessage: CONFIG.successMessages.editLiveMessage,
      submitEvent: !this.isEdit ? ADD_LIVE_STREAM_REQUEST : EDIT_LIVE_STREAM_REQUEST,
      availabilityExpiration: {
        avDate: '',
        avTime: '',
        expDate: '',
        expTime: '',
      },
      liveTypes: [
        {
          code: 'transcode',
          name: 'Live Transcode',
        },
        {
          code: 'payback',
          name: 'Linked Playback URL',
        },
      ],
      tabErrors: {
        general: false,
        source: false,
        availability: false,
      },
      formData: {
        mode: 'transcode',
        mediaTitle: '',
        description: '',
        deliveryEventName: '',
        hlsPaybackUrl: '',
        dashPaybackUrl: '',
        deliveryTargets: [],
        poster: '',
        startDate: new Date(),
        endDate: new Date(),
        startTime: '',
        endTime: '',
        folderId: '',
        isToggleRecord: false,
        isToggleApprove: false,
        isToggleRecurring: false,
        week: CONFIG.week.map((i) => ({ ...i })),
        editFolders: [],
        categories: [],
      },
      formFields: {
        folderId: {
          type: 'text',
          name: 'Target Media Folder',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        mediaTitle: {
          type: 'text',
          name: 'mediaTitle',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        description: {
          name: 'description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: false,
            max: 500,
          },
        },
        deliveryEventName: {
          type: 'text',
          name: 'deliveryEventName',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 100,
          },
        },
        hlsPaybackUrl: {
          type: 'text',
          name: 'hlsPaybackUrl',
          formPrefix: this.formPrefix,
          hideAsterisk: true,
          rules: {
            required: false,
            max: 4096,
          },
        },
        dashPaybackUrl: {
          type: 'text',
          name: 'dashPaybackUrl',
          formPrefix: this.formPrefix,
          hideAsterisk: true,
          rules: {
            required: false,
            max: 4096,
          },
        },
        startTime: {
          type: 'time',
          name: 'startTime',
          min: '00:00:00',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        endTime: {
          type: 'time',
          name: 'endTime',
          min: '00:00:00',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        startDate: {
          type: 'date',
          name: 'startDate',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        endDate: {
          type: 'date',
          name: 'endDate',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        recurring: {
          type: 'text',
          name: 'recurring',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        mediaTitle: [
          `${this.formPrefix}.name`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
        paybackUrl: [
          `${this.formPrefix}.mediaItemSources.*`,
        ],
        deliveryEventName: [
          `${this.formPrefix}.mediaItemSources.0.path`,
        ],
        startTime: [
          `${this.formPrefix}.recordSchedule.startTime`,
        ],
        endTime: [
          `${this.formPrefix}.recordSchedule.endTime`,
        ],
        startDate: [
          `${this.formPrefix}.recordSchedule.startDate`,
        ],
        endDate: [
          `${this.formPrefix}.recordSchedule.endDate`,
        ],
        recurring: [
          `${this.formPrefix}.recordSchedule.recurring`,
        ],
        avDate: [
          `${this.formPrefix}.availableFrom`,
        ],
        expDate: [
          `${this.formPrefix}.expirationAt`,
        ],
        categories: [
          `${this.formPrefix}.categories`,
        ],
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.liveStream.isLoading,
      isDeliveryChanged: (state) => state.liveStream.isDeliveryChanged,
      timezone: (state) => (state.common.profile.timezone
        ? state.common.profile.timezone
        : moment.tz.guess()),
      live: (state) => state.liveStream.live,
      mediaItem: (state) => state.mediaItem.mediaItem,
      folders: (state) => state.liveStream.folders.map((item) => (
        {
          name: item.name,
          code: item.id,
        }
      )),
    }),
    ...mapGetters([
      'getCurrentAccount',
      'getCurrentAccountId',
      'getRootMediaFolderId',
      'getMainProfile',
    ]),
  },
  mounted() {
    const params = {
      accountId: this.getCurrentAccountId,
    };
    this.$store.dispatch(GET_FOLDERS_LIST_REQUEST, params);
    moment.tz.setDefault(this.timezone);

    if (this.isEdit) {
      this.initFormData();
    } else {
      this.formData.mode = 'transcode';
    }
  },
  methods: {
    initFormData() {
      const {
        name,
        description,
        credentials,
        deliveryTargets,
        recordSchedule,
        mediaItemSources,
      } = this.mediaItem;
      this.formData.mediaTitle = name;
      this.formData.description = description;
      this.formData.categories = this.mediaItem.categories;

      if (this.mediaItem.type === 'livestream_url') {
        this.formData.mode = 'payback';

        if (mediaItemSources.length) {
          const hls = mediaItemSources.find((item) => item.format === 'hls');
          const dash = mediaItemSources.find((item) => item.format === 'mpeg-dash');

          this.formData.hlsPaybackUrl = hls && hls.path;
          this.formData.dashPaybackUrl = dash && dash.path;
        }

        return;
      }

      this.formData.mode = 'transcode';
      this.formData.deliveryEventName = credentials.streamName;
      this.formData.deliveryTargets = this.transformBEDeliveryTargets(deliveryTargets);
      this.formData.poster = this.getPosterUrl(this.mediaItem);
      this.formData.isToggleRecord = !!recordSchedule;
      if (recordSchedule) {
        this.formData.startDate = this.transformStartDate(recordSchedule);
        this.formData.endDate = this.transformFinishDate(recordSchedule);
        this.formData.startTime = this.transformStartTime(recordSchedule);
        this.formData.endTime = this.transformFinishTime(recordSchedule);
        this.formData.isToggleApprove = recordSchedule.publishVod;
        this.formData.isToggleRecurring = recordSchedule.recurring;
        this.formData.folderId = recordSchedule.mediaFolder ? recordSchedule.mediaFolder.id : '';
        this.formData.week.find((day) => day.code === 'Monday').recurring = recordSchedule.everyMon;
        this.formData.week.find((day) => day.code === 'Tuesday').recurring = recordSchedule.everyTue;
        this.formData.week.find((day) => day.code === 'Wednesday').recurring = recordSchedule.everyWed;
        this.formData.week.find((day) => day.code === 'Thursday').recurring = recordSchedule.everyThu;
        this.formData.week.find((day) => day.code === 'Friday').recurring = recordSchedule.everyFri;
        this.formData.week.find((day) => day.code === 'Saturday').recurring = recordSchedule.everySat;
        this.formData.week.find((day) => day.code === 'Sunday').recurring = recordSchedule.everySun;
      }
    },
    transformStartTime(recordSchedule) {
      const dateTime = moment(recordSchedule.startDateTimeTz).tz(this.timezone);
      return dateTime.isValid() ? dateTime.format('HH:mm:ss') : '';
    },
    transformFinishTime(recordSchedule) {
      const dateTime = moment(recordSchedule.finishDateTimeTz).tz(this.timezone);
      return dateTime.isValid() ? dateTime.format('HH:mm:ss') : '';
    },
    transformStartDate(recordSchedule) {
      const dateTime = moment(recordSchedule.startDateTimeTz).tz(this.timezone);
      return dateTime.isValid() ? dateTime.toDate() : '';
    },
    transformFinishDate(recordSchedule) {
      const dateTime = moment(recordSchedule.finishDateTimeTz).tz(this.timezone);
      return dateTime.isValid() ? dateTime.toDate() : '';
    },
    getStartTime() {
      const date = moment(this.formData.startDate).format('YYYY-MM-DD');
      const time = this.formData.startTime;
      const dateTime = moment(`${date}T${time}`).utc();
      return dateTime.isValid() ? dateTime.format('HH:mm:ss') : '';
    },
    getFinishTime() {
      const date = this.formData.isToggleRecurring
        ? moment(this.formData.startDate).format('YYYY-MM-DD')
        : moment(this.formData.endDate).format('YYYY-MM-DD');
      const time = this.formData.endTime;
      const dateTime = moment(`${date}T${time}`).utc();
      return dateTime.isValid() ? dateTime.format('HH:mm:ss') : '';
    },
    getStartDate() {
      const date = this.formData.startDate instanceof Date
        ? moment(this.formData.startDate).format('YYYY-MM-DD')
        : this.formData.startDate;
      const time = this.formData.startTime;
      const dateTime = moment(`${date}T${time}`).utc();
      return dateTime.isValid() ? dateTime.format('YYYY-MM-DD') : '';
    },
    getFinishDate() {
      const date = this.formData.endDate instanceof Date
        ? moment(this.formData.endDate).format('YYYY-MM-DD')
        : this.formData.endDate;
      const time = this.formData.isToggleRecurring
        ? this.formData.startTime
        : this.formData.endTime;
      const dateTime = moment(`${date}T${time}`).utc();
      return dateTime.isValid() ? dateTime.format('YYYY-MM-DD') : '';
    },
    onTagManagementChange(value) {
      this.selectedTags = value;
    },
    addLiveStream() {
      this.$refs.validationObserver.validate()
        .then((success) => {
          if (!success) {
            return;
          }
          if (this.$refs.posterImage.isValid()) {
            this.submit();
          }
        });
    },
    handlePosterChange(img) {
      this.isPosterChanged = true;
      this.formData.poster = img;
    },
    handleCategoryChange(value) {
      this.formData.categories = value;
      this.categoriesError = '';
    },
    onSubmitFail(error) {
      this.$store.dispatch(CHANGE_DELIVERY_TARGET_REQUEST, false);
      const {
        lockedMessage,
        recordingErrorMessage,
        recordingTypeError,
      } = CONFIG.errorMessages;

      if (error.payload) {
        this.$toasted.global.error({
          message: lockedMessage,
        });
      }

      this.handleFormChange(true);
      const errorObj = error.form_errors;

      const formErrors = this.getServerFormErrors(error);

      if (errorObj.children && errorObj.children.name) {
        this.tabErrors.general = true;
      }

      if (errorObj.children && (errorObj.children.mediaItemSources
        || (errorObj.errors && errorObj.errors[0].error === 'TOO_FEW_ERROR')
        || errorObj.children.deliveryTargets)) {
        this.tabErrors.source = true;
      }

      if (errorObj.children
        && (errorObj.children.availableFrom || errorObj.children.expirationAt)) {
        this.tabErrors.availability = true;
      }

      if (errorObj.errors && errorObj.errors[0].error === recordingTypeError) {
        this.$toasted.global.error({
          message: recordingErrorMessage,
        });
      }

      if (errorObj
        && errorObj.errors
        && errorObj.errors[0].error === 'TOO_FEW_ERROR') {
        formErrors.hlsPaybackUrl = 'This field is required';
        formErrors.dashPaybackUrl = 'This field is required';
      }

      const recordSchedule = errorObj && errorObj.children && errorObj.children.recordSchedule
        && errorObj.children.recordSchedule.children;

      this.recurringErrors = recordSchedule && recordSchedule.recurring
        ? recordSchedule.recurring.errors[0].error_description : null;

      const liveErr = error.form_errors.children.mediaItemSources
        ? error.form_errors.children.mediaItemSources.children[0].children.path
        : null;

      if (liveErr) {
        this.liveTranscodeError = liveErr;
      }

      let deliveryTargetsItem = null;
      if (errorObj.children && errorObj.children.deliveryTargets) {
        deliveryTargetsItem = errorObj.children.deliveryTargets;
        // eslint-disable-next-line max-len
        this.formData.deliveryTargets = this.transformBEDeliveryTargets(this.mediaItem.deliveryTargets);
      }

      this.errors = deliveryTargetsItem && deliveryTargetsItem.children
        ? deliveryTargetsItem.children : null;

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        const requestData = this.getRequestData();

        if (requestData.data.mediaItemSources && requestData.data.mediaItemSources.length) {
          requestData.data.mediaItemSources.forEach((item, index) => {
            const error = formErrors[`paybackUrl.${index}.path`];

            if (error) {
              if (item.format === 'hls') {
                formErrors.hlsPaybackUrl = error;
              } else {
                formErrors.dashPaybackUrl = error;
              }
            }
          });
        }

        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    handleClose() {
      this.isOpen = false;
      this.$router.push(`/media/folder/${this.$router.history.current.params.folderId}`);
    },
    handleSelectTab(id) {
      if (id === 'source') {
        this.tabErrors.source = false;
      }

      if (id === 'general') {
        this.tabErrors.general = false;
      }

      if (id === 'availability') {
        this.tabErrors.availability = false;
      }
    },
    getRequestData() {
      const allMediaFolder = Number(this.getRootMediaFolderId);
      const currentFolder = Number(this.$router.history.current.params.folderId);
      if (this.formData.mode === 'payback') {
        const data = {
          name: this.formData.mediaTitle,
          description: this.formData.description,
          categories: this.formData.categories.map((category) => category.id),
          mediaItemSources: [],
          posterImage: this.formData.poster,
        };

        if (this.availabilityExpiration.avDate) {
          data.availableFrom = this.getDateTimeAv();
        }

        if (this.availabilityExpiration.expDate) {
          data.expirationAt = this.getDateTimeExp();
        }

        if (this.formData.hlsPaybackUrl) {
          data.mediaItemSources.push({
            path: this.formData.hlsPaybackUrl,
            format: 'hls',
          });
        }

        if (this.formData.dashPaybackUrl) {
          data.mediaItemSources.push({
            path: this.formData.dashPaybackUrl,
            format: 'mpeg-dash',
          });
        }

        if (this.isEdit) {
          const searchMediaTags = this.mediaItem.searchMediaTags.map((item) => item.id);
          const workflowMediaTags = this.mediaItem.workflowMediaTags.map((item) => item.id);
          if (!this.isPosterChanged) {
            delete data.posterImage;
          }
          data.searchMediaTags = searchMediaTags;
          data.workflowMediaTags = workflowMediaTags;

          return {
            accountId: this.getCurrentAccountId,
            itemId: this.mediaItem.id,
            data,
          };
        }

        data.mediaFolder = currentFolder !== allMediaFolder ? currentFolder : null;

        return {
          accountId: this.getCurrentAccountId,
          mode: this.formData.mode,
          data,
        };
      }

      if (this.formData.isToggleRecord) {
        if (this.isEdit) {
          const data = {
            name: this.formData.mediaTitle,
            description: this.formData.description,
            categories: this.formData.categories.map((category) => category.id),
            posterImage: this.formData.poster,
            recordSchedule: {
              publishVod: this.formData.isToggleApprove,
              startTime: this.getStartTime(),
              finishTime: this.getFinishTime(),
              startDate: this.getStartDate(),
              finishDate: this.getFinishDate(),
              recurring: this.formData.isToggleRecurring,
              everyMon: this.formData.isToggleRecurring
                ? this.formData.week.find((day) => day.code === 'Monday').recurring
                : false,
              everyTue: this.formData.isToggleRecurring
                ? this.formData.week.find((day) => day.code === 'Tuesday').recurring
                : false,
              everyWed: this.formData.isToggleRecurring
                ? this.formData.week.find((day) => day.code === 'Wednesday').recurring
                : false,
              everyThu: this.formData.isToggleRecurring
                ? this.formData.week.find((day) => day.code === 'Thursday').recurring
                : false,
              everyFri: this.formData.isToggleRecurring
                ? this.formData.week.find((day) => day.code === 'Friday').recurring
                : false,
              everySat: this.formData.isToggleRecurring
                ? this.formData.week.find((day) => day.code === 'Saturday').recurring
                : false,
              everySun: this.formData.isToggleRecurring
                ? this.formData.week.find((day) => day.code === 'Sunday').recurring
                : false,
              mediaFolder: this.formData.folderId,
            },
            deliveryTargets: this.transformDeliveryTargets(this.formData.deliveryTargets),
          };

          if (!this.isPosterChanged) {
            delete data.posterImage;
          }

          if (this.mediaItem.recordSchedule && this.mediaItem.recordSchedule.hasRunningRecorder) {
            delete data.recordSchedule.hasRunningRecorder;
          }

          if (!this.isDeliveryChanged) {
            delete data.deliveryTargets;
          }

          return {
            accountId: this.getCurrentAccountId,
            itemId: this.mediaItem.id,
            data,
          };
        }

        const data = {
          name: this.formData.mediaTitle,
          description: this.formData.description,
          categories: this.formData.categories.map((category) => category.id),
          mediaFolder: currentFolder !== allMediaFolder ? currentFolder : null,
          mediaItemSources: [{
            path: this.formData.deliveryEventName,
          }],
          posterImage: this.formData.poster,
          availableFrom: this.getDateTimeAv(),
          expirationAt: this.getDateTimeExp(),
          recordSchedule: {
            publishVod: this.formData.isToggleApprove,
            startTime: this.getStartTime(),
            finishTime: this.getFinishTime(),
            startDate: this.getStartDate(),
            finishDate: this.getFinishDate(),
            recurring: this.formData.isToggleRecurring,
            everyMon: this.formData.isToggleRecurring
              ? this.formData.week.find((day) => day.code === 'Monday').recurring
              : false,
            everyTue: this.formData.isToggleRecurring
              ? this.formData.week.find((day) => day.code === 'Tuesday').recurring
              : false,
            everyWed: this.formData.isToggleRecurring
              ? this.formData.week.find((day) => day.code === 'Wednesday').recurring
              : false,
            everyThu: this.formData.isToggleRecurring
              ? this.formData.week.find((day) => day.code === 'Thursday').recurring
              : false,
            everyFri: this.formData.isToggleRecurring
              ? this.formData.week.find((day) => day.code === 'Friday').recurring
              : false,
            everySat: this.formData.isToggleRecurring
              ? this.formData.week.find((day) => day.code === 'Saturday').recurring
              : false,
            everySun: this.formData.isToggleRecurring
              ? this.formData.week.find((day) => day.code === 'Sunday').recurring
              : false,
            mediaFolder: this.formData.folderId,
          },
          deliveryTargets: this.transformDeliveryTargets(this.formData.deliveryTargets),
        };

        if (!this.isDeliveryChanged) {
          delete data.deliveryTargets;
        }

        return {
          accountId: this.getCurrentAccountId,
          mode: this.formData.mode,
          data,
        };
      }

      if (this.isEdit) {
        const searchMediaTags = this.mediaItem.searchMediaTags.map((item) => item.id);
        const workflowMediaTags = this.mediaItem.workflowMediaTags.map((item) => item.id);
        const data = {
          name: this.formData.mediaTitle,
          description: this.formData.description,
          categories: this.formData.categories.map((category) => category.id),
          searchMediaTags,
          workflowMediaTags,
          posterImage: this.formData.poster,
          deliveryTargets: this.transformDeliveryTargets(this.formData.deliveryTargets),
        };

        if (!this.isPosterChanged) {
          delete data.posterImage;
        }

        if (!this.isDeliveryChanged) {
          delete data.deliveryTargets;
        }

        return {
          accountId: this.getCurrentAccountId,
          itemId: this.mediaItem.id,
          data,
        };
      }

      const data = {
        name: this.formData.mediaTitle,
        description: this.formData.description,
        categories: this.formData.categories.map((category) => category.id),
        mediaFolder: currentFolder !== allMediaFolder ? currentFolder : null,
        mediaItemSources: [{
          path: this.formData.deliveryEventName,
        }],
        availableFrom: this.getDateTimeAv(),
        expirationAt: this.getDateTimeExp(),
        posterImage: this.formData.poster,
        deliveryTargets: this.transformDeliveryTargets(this.formData.deliveryTargets),
      };

      if (!this.isDeliveryChanged) {
        delete data.deliveryTargets;
      }

      return {
        accountId: this.getCurrentAccountId,
        mode: this.formData.mode,
        data,
      };
    },
    transformDeliveryTargets(deliveryTargets) {
      return deliveryTargets.map((item) => {
        delete item.status;
        delete item.id;

        if (item.type === 'facebook') {
          const result = {};
          Object.entries(item.params).forEach(([key, value]) => {
            result[key] = value;
          });
          result.type = item.type;
          result.enabled = item.enabled;
          result.recorderSync = item.recorderSync;
          delete result.status;
          delete result.id;
          delete result.facebookDestName;
          delete result.useSsl;
          if (result.facebookDestType !== 'timeline') {
            delete result.facebookPrivacy;
          }
          return result;
        }

        return item;
      });
    },
    transformBEDeliveryTargets(deliveryTargets) {
      return deliveryTargets.map((item) => {
        if (item.type === 'facebook') {
          const result = {
            params: {},
            fbData: {},
          };
          Object.entries(item).forEach(([key, value]) => {
            result[key] = value;
          });
          Object.entries(item).forEach(([key, value]) => {
            result.params[key] = value;
          });
          Object.entries(item).forEach(([key, value]) => {
            result.fbData[key] = value;
          });
          return result;
        }

        return item;
      });
    },
    handleFormChangeLive() {
      this.handleFormChange(true);
    },
    onSubmitSuccess() {
      if (this.formData.mode === 'payback') {
        this.$toasted.global.success({
          message: 'The Live Stream has been successfully added',
        });
      }

      this.tabErrors.general = false;
      this.tabErrors.source = false;
      this.tabErrors.availability = false;

      this.$store.dispatch(CHANGE_DELIVERY_TARGET_REQUEST, false);

      if (this.isEdit) {
        this.$toasted.global.success({
          message: this.successMessage,
        });
        setTimeout(() => {
          const mediaPage = Number(localStorage.getItem('currentPage-media-list'));
          this.onClose();
          this.getMediaList(mediaPage);
          // eslint-disable-next-line max-len
          this.formData.deliveryTargets = this.transformBEDeliveryTargets(this.mediaItem.deliveryTargets);
        }, CONFIG.routing.redirectFromAddAcc);
        return;
      }

      this.formData.mediaTitle = '';
      this.formData.description = '';
      this.formData.deliveryEventName = '';
      this.formData.hlsPaybackUrl = '';
      this.formData.dashPaybackUrl = '';
      this.formData.startDate = new Date();
      this.formData.endDate = new Date();
      this.recurringErrors = '';
      this.isOpen = true;
      this.handleFormChange(false);

      if (this.live.type === 'livestream_url') {
        this.$router.push(`/media/folder/${this.$router.history.current.params.folderId}`);
      }
    },
    getPosterUrl(item) {
      return item.posterImage ? `${item.posterImage}?height=360` : '';
    },
    isRecorderDisabled(mediaItem) {
      return this.isEdit
        ? mediaItem.recordSchedule && mediaItem.recordSchedule.hasRunningRecorder
        : false;
    },
    handleRecorderToggle() {
      if (!this.formData.isToggleRecord && this.getRecorderSyncDeliveryTargets()) {
        this.isRecorderToggleOpen = true;
      }
    },
    handleRecorderToggleClose() {
      this.isRecorderToggleOpen = false;
    },
    handleRecorderToggleDecline() {
      this.formData.isToggleRecord = true;
    },
    getRecorderToggleConfirmationMessage() {
      return this.recorderToggleConfirmationMessage.replace('{targets}', this.getRecorderSyncDeliveryTargets());
    },
    getRecorderSyncDeliveryTargets() {
      if (this.formData.deliveryTargets instanceof Array) {
        return this.formData.deliveryTargets
          .filter((item) => item.recorderSync && item.enabled)
          .length;
      }
      return 0;
    },
    getDateTimeAv() {
      let avDate = '';

      if (this.availabilityExpiration.avDate) {
        avDate += moment(this.availabilityExpiration.avDate).format('YYYY-MM-DD');
        avDate += this.parseTimeForBE();
      }
      return moment.tz(avDate, this.getTimezone()).utc();
    },
    getDateTimeExp() {
      let expDate = '';

      if (this.availabilityExpiration.expDate) {
        expDate += moment(this.availabilityExpiration.expDate).format('YYYY-MM-DD');
        expDate += this.parseTimeForBEExp();
      }
      return moment.tz(expDate, this.getTimezone()).utc();
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
    parseTimeForBE() {
      return this.availabilityExpiration.avTime
        ? `T${this.availabilityExpiration.avTime.HH}:${this.availabilityExpiration.avTime.mm}:00`
        : 'T12:00:00';
    },
    parseTimeForBEExp() {
      return this.availabilityExpiration.expTime
        ? `T${this.availabilityExpiration.expTime.HH}:${this.availabilityExpiration.expTime.mm}:00`
        : 'T12:00:00';
    },
    onLiveTypeChange(e) {
      this.formData.mode = e.target.value;
    },
    backTo() {
      this.$router.go(-1);
    },
    onFoldersChange(value) {
      this.formData.folderId = value ? value.code : value;
      this.foldError = '';
    },
  },
};
</script>

<style scoped>
  .media-information {
    margin-bottom: 40px;
    /*max-width: 700px;*/
    width: 100%;
  }

  .button {
    height: auto;
    padding: 10px 15px;
    text-align: center;
    vertical-align: middle;
  }

  .live-transmux {
    display: flex;
    justify-content: space-between;
    max-width: 700px;
    width: 100%;
  }

  .empty-player {
    background-color: #383b40;
    color: #4b4f55;
    display: table;
    height: 188px;
    max-width: 335px;
    text-align: center;
    width: 100%;
  }

  .empty-player-text {
    display: table-cell;
    vertical-align: middle;
  }

  .hls-payback {
    margin-right: 20px;
    max-width: 335px;
    width: 100%;
  }

  .dash-payback {
    max-width: 335px;
    width: 100%;
  }

  .live-preview {
    margin: 10px 0;
  }

  .event-name {
    /*width: 470px;*/
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .toggle-wrapper,
  .date-wrapper {
    align-items: center;
    display: flex;
    margin-right: 30px;
    width: 17vw;
  }

  .toggle-wrapper {
    width: auto;
  }

  .wrapper-toggles,
  .wrapper-dates {
    display: flex;
    margin-bottom: 15px;
  }

  .wrapper-days-recurring {
    display: flex;
    flex-direction: column-reverse;
  }

  .wrapper-days {
    display: flex;
    width: 225px;
    margin-right: 20px;
  }

  .record {
    margin: 10px 0;
  }

  .wrapper-week {
    display: flex;
    flex-wrap: wrap;
    margin-right: 3px;
    padding-top: 10px;
    width: 251px;
  }

  .wrapper-folders {
    margin-bottom: 20px;
  }

  .media-information-wrapper {
    display: flex;
    height: 100%;
  }

  .poster-image {
    margin: 0 20px 0 80px;
    padding-top: 46px;
    width: 100%;
  }

  .poster-image-title {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .transcode-tab {
    display: flex;
  }

  .toggle-title {
    margin-left: 10px;
  }

  .external-delivery {
    border: 1px solid #383b40;
    padding: 10px 20px;
  }

  .time-wrapper {
    width: 225px;
  }

  .date-time-input-wrapper {
    width: 100%;
  }

  .date-time-input-wrapper:first-child {
    margin-right: 20px;
  }

  .radios-group {
    display: flex;
  }

  .radio-type {
    margin-right: 90px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .general-form {
    width: 700px;
  }

  .live-types-tab {
    display: flex;
  }

  .live-transcode {
    border: 1px solid #383b40;
    width: 700px;
    margin-right: 30px;
    padding: 10px 20px;
    height: calc(100vh - 280px);
    overflow: auto;
  }

  .time-block {
    width: 100%;
  }

  .editor {
    padding-bottom: 0;
  }

  .system {
    color: #4c5057;
    font-size: 18px;
    font-weight: 500;
    padding-right: 4px;
  }

  @media all and (max-width: 1666px) {
    .live-transcode {
      width: 550px;
    }
  }

  @media all and (max-width: 1500px) {
    .live-transcode {
      width: 450px;
    }
  }

  @media all and (max-width: 1366px) {
    .live-transcode {
      width: 400px;
    }
  }

  @media all and (max-width: 1070px) {
    .media-information-wrapper {
      display: block;
      margin-bottom: 40px;
    }

    .media-information {
      margin-bottom: 0;
    }

    .poster-image {
      margin: 0;
      padding-top: 20px;
    }

    .transcode-tab {
      display: block;
    }

    .date-wrapper {
      width: auto;
    }

    .event-name {
      margin-bottom: 20px;
    }
  }

  .categories-wrap {
    margin-bottom: 26px;
  }
</style>
